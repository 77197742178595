<template>
  <div class="file_download">
<!--    <NavBar title="报告下载"></NavBar>-->
    <div class="search" @click="tmpList.length>0?showPicker = true:showPicker = false">
      <input v-model="input_year" type="text" class="search_input" disabled="disabled" placeholder="请选择相关年份..." >
      <div class="search_icon"></div>
    </div>
    <div class="item" v-for="v in fileList" :key="v.id">
      <img src="../assets/pdf 1.png" >
      <p>{{v.year}}年电影放映质量检测报告</p>
      <van-button @click="down(v.id)" type="info">下载</van-button>
    </div>
    <el-empty v-if="emptyFlag" description="还没有可下载报告呢"  image="https://img01.yzcdn.cn/vant/empty-image-default.png"></el-empty>

    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
          show-toolbar
          :columns="columns"
          @cancel="showPicker = false"
          @confirm="onConfirm"
      />
    </van-popup>

    <div class="zhezhao" v-if="zhezhaoShow"></div>
  </div>
</template>

<script>
import NavBar from "../components/NavBar";
import {getFileList, getFilePdf} from '../axios/api'
import {Button, Toast, Picker, Popup} from 'vant';
import {Loading,Empty} from "element-ui";
export default {
  name: "FileDownload",
  components:{
    NavBar,
    [Button.name]:Button,
    [Empty.name]:Empty,
    [Picker.name]:Picker,
    [Popup.name]:Popup,
  },
  data(){
    return{
      fileList:[],
      tmpList:[], //暂时存放器
      input_year:'',
      emptyFlag:false,
      showPicker: false,
      columns: [],
      u:navigator.userAgent, //设备信息
      zhezhaoShow:false
    }
  },
  created() {
    //路由存在传值，不用发送网络请求
    if(this.$route.query.url){
      if(this.u.toLowerCase().indexOf('micromessenger') !== -1){
        //用户在微信内打开（如果有问题可以再次考虑添加ios系统的判断）
        //展示引导提示
        this.zhezhaoShow=true
      }else{
        //用户在微信外打开，直接按照路由的传值来跳转
        window.location.href = this.$route.query.url;
      }
    }else{
      //路由不存在传值，发送网络请求
      //即使用户意外在微信外打开，浏览器中没有token信息，也会让用户重新登录（或者再次判断用户是否在微信内打开）
      /*判断是否存在token 获取影厅列表*/
      if(localStorage.getItem("token")){
        let loadingInstance = Loading.service({ fullscreen: true , text: "加载中"});
        getFileList({token:localStorage.getItem("token")}).then(respose=>{
          if(respose.state==="1"){
            this.fileList=respose.data
            // this.fileList=[{id: "66", year: "2023"},{id: "65", year: "2022"},{id: "64", year: "2021"},{id: "63", year: "2020"},{id: "62", year: "2019"},{id: "61", year: "2018"}]  //假数据测试
            this.fileList.forEach(v=>this.columns.push(v.year))
            this.tmpList=this.fileList; //浅拷贝？？？
          }else {
            /*没有数据 空状态显示 */
            this.emptyFlag=true
          }
          loadingInstance.close();
        }).catch(error=>{
          console.log(error)
        })
      }else{
        // 没有用户token
        Toast({message:"非法操作，\n请重新登录",icon:"cross"})
        /*Message.error("非法操作，请重新登录");*/
        this.$router.push("/cinemaLogin")
      }
    }

  },
  methods:{
    down(id){
      let loadingInstance = Loading.service({ fullscreen: true , text: "加载中"});
      getFilePdf({
        token:localStorage.getItem("token"),
        did:id
      }).then(respose=>{
        //判断设备信息 ios不支持window.open()
        let isAndroid = this.u.indexOf("Android") > -1 || this.u.indexOf("Adr") > -1; //android终端
        let isiOS = !!this.u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

        if(isAndroid) {  //android终端
          window.open(respose.data);
        }else if(isiOS) {   //ios终端
          // 路由守卫 跳转到pdf时判断 需要解决token的问题 ×
          // 再开一个页面 布局？？ 用户步骤：提示打开外部浏览器=>点击打开pdf页面=>展示pdf=>下载pdf
          // 点击下载时在微信提示=修改路由=增加pdf地址参数=creater判断是否存在参数
          // 首先判断用户是否在微信里打开，还是已经跳转到浏览器
          if(this.u.toLowerCase().indexOf('micromessenger') !== -1){
            //跳转路由
            this.$router.replace({path:"/fileDownload",query:{url:respose.data}}) //不记录本次路由
            //显示用户引导步骤
            this.zhezhaoShow=true
          }else{
            //如果用户在浏览器登录并使用，我们可以直接跳转到pdf地址
            window.location.href = respose.data;
          }
        }else{ //PC端
          window.open(respose.data);
        }
        loadingInstance.close();
      }).catch(error=>{
        console.log(error);
      })
    },
    /*search(){
      if(this.input_year==''){
        this.fileList=this.tmpList;
      }
      this.fileList=this.fileList.filter(v=>{
        return v.year.indexOf(this.input_year)!==-1;
      })
      if(this.fileList.length===0){
        Toast.fail("未找到相关报告");
        this.fileList=this.tmpList;
      }
      this.input_year=""
    },*/
    onConfirm(value) {
      this.fileList=this.tmpList;
      //选择年份后 过滤
      //如果数据过多 可以优化搜索算法
      this.fileList=this.fileList.filter(v=>{
        return v.year===value;
      })
      this.showPicker = false;
    },
  },
  // 路由传值可能出现无效现象，那么请考虑用监听
  /*computed:{
    Nquery(){
      console.log(this.$route.query.url )
    }
  }*/
}
</script>

<style scoped>
.zhezhao{
  width: 750px;
  overflow: hidden;
  background: url(../assets/bgzz.png) top center no-repeat;
  background-size: 750px;
  position: fixed;
  top:0;
  bottom: 0;
  left:0;
  right: 0;
}
.file_download{
  padding-top: 23px;
  min-height: calc(100vh - 23px);
}
.search {
  margin: 0 auto 50px;
  width: 690px;
  height: 87px;
  background: #FFFFFF;
  box-shadow: 0px 4px 13px 1px rgba(0, 0, 0, 0);
  border-radius: 46px;
  line-height: 87px;
  display: flex;
  align-items: center;
}
.search .search_input {
  border: 0px;
  height: 39px;
  width: 545px;
  margin-left: 37px;
  font-size: 28px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  line-height: 33px;
  background-color: transparent; /*输入框有背景颜色*/
}
.search .search_icon {
  width: 90px;
  height: 79px;
  background-image: url("../assets/Group 232.png");
  background-repeat: no-repeat;
  background-size: 91px 80px;
  background-position: 0 0 ;
  float: right;
  margin-right: 5px;
  line-height: 79px;
  cursor: pointer;
}
.item {
  width: 690px;
  height: 125px;
  background: #FFFFFF;
  border-radius: 20px 20px 20px 20px;
  margin: 20px auto 0;
  display: flex;
  align-items: center;
}
.item img {
  margin: 0 39px 0 28px;
  width: 58px;
  height: 58px;
}
.item p {
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #434854;
  width: 451px;
  overflow: hidden;
}
.item button {
  width: 95px;
  height: 44px;
  border-radius: 8px;
  border: 0px;
  background-color: #516FFA;
  font-size: 28px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 1;
  padding: 0;
  cursor: pointer;
}
/*空状态样式*/
>>>.van-empty{
  width: 750px;
  height: 274px;
}
>>>.van-empty__image{
  width: 160px;
  height: 160px;
}
>>>.van-empty__image img{
  pointer-events:none;
}


>>>.van-picker {
  width: 750px;
}
>>>.van-picker .van-picker__toolbar {
  width: 688px;
  height: 50px;
  margin: 0 36px 0 30px;
  padding: 30px 0 20px 0;
  border-bottom: 1px solid #F2F2F2;
}
>>>.van-picker .van-picker__toolbar .van-picker__cancel {
  font-size: 36px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #5270FB;
  padding: 0;
}
>>>.van-picker .van-picker__toolbar .van-picker__confirm {
  font-size: 36px;
  font-family: PingFang SC-Heavy, PingFang SC;
  font-weight: 800;
  color: #5270FB;
  padding: 0;
}
>>>.van-picker__columns{
  /*height: 456px!important;*/
}
>>>.van-picker .van-picker__columns .van-picker-column__wrapper .van-picker-column__item {
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #404040;
  /*line-height: 76px;
  height: 76px!important;*/
}
>>>.van-picker .van-picker__columns .van-picker-column__wrapper .van-picker-column__item--selected {
  width: 551px;
  height: 76px;
  background: #F2F2F2;
  border-radius: 10px;
  margin: 0 auto;
}
</style>